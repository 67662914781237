<template>
  <div>
    <b-modal
      ref="modal-specifications"
      no-close-on-backdrop
      size="sm"
      :title="title + ' SPECIFICATION'"
      @hidden="$emit('hidden')"
    >
      <div class="m-2">
        <validation-observer ref="observer">
          <div class="form-row">
            <div class="form-group col-md-12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="specifications"
              >
                <b-form-group label="SPECIFICATION">
                  <b-form-input
                    v-model="specifications"
                    autofocus
                    type="text"
                    class="form-control"
                    :class="errors[0]"
                    @input="onSpecificationInput"
                  />
                </b-form-group>
              </validation-provider>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="subCategorys"
              >
                <b-form-group
                  :class="errors[0]"
                  label="SUBCATEGORY"
                >
                  <v-select
                    v-model="subCategoryId"
                    label="name"
                    :options="listSubCategory"
                    :reduce="(item) => item.id"
                  /> </b-form-group>
              </validation-provider>
            </div>
          </div>
        </validation-observer>
      </div>
      <template #modal-footer="{ cancel }">
        <div>
          <button-cancel @click="cancel()">
            CANCEL
          </button-cancel>
        </div>
        <div>
          <b-button
            variant="primary"
            @click="setAction()"
          ><feather-icon icon="SaveIcon" />
            {{ textButton }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SettingsService from '@/views/logistic/views/settings/services/settings.service.js';
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import ButtonCancel from '@/views/commons/utilities/ButtonCancel.vue';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'is-invalid',
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ButtonCancel,
  },

  props: {
    dataSpecifications: {
      type: Object,
    },
    isSpecifications: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subCategoryId: '',
      specifications: '',
      idSpecifications: 0,
      listSubCategory: [],
      title: 'ADD',
      textButton: 'SAVE',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.toggleModal('modal-specifications');
    this.getSubCategory();

    if (this.isSpecifications) {
      this.showSpecifications();
      this.title = 'UPDATE';
      this.textButton = 'UPDATE';
    }
  },

  methods: {
    onSpecificationInput(value) {
      this.specifications = value.toUpperCase();
    },
    async getSubCategory() {
      try {
        const request = await SettingsService.getSubCategory();
        if (request.status == 200) {
          this.listSubCategory = request.data || [];
          return this.listSubCategory;
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async createSpecification() {
      const result = await this.$refs.observer.validate();

      if (result) {
        try {
          const params = {
            subCategoryId: this.subCategoryId,
            specifications: this.specifications,
            user_id: this.currentUser.user_id,
          };

          const resultState = await this.showConfirmSwal();

          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await SettingsService.saveSpecification(params);
            if (resul.status == 200) {
              this.showSuccessSwal();
              this.cancel();
              this.$emit('onCreateSpecification');
            }
          }
        } catch (error) {
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, 'Info');
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          this.removePreloader();
        }
      }
    },
    async updateSpecification() {
      const result = await this.$refs.observer.validate();

      if (result) {
        try {
          const params = {
            subCategoryId: this.subCategoryId,
            specifications: this.specifications,
            id: this.idSpecifications,
            user_id: this.currentUser.user_id,
          };

          const resultState = await this.showConfirmSwal();

          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await SettingsService.updateSpecifications(params);
            if (resul.status == 200) {
              this.showSuccessSwal();
              this.cancel();
              this.$emit('onCreateSpecification');
            }
          }
        } catch (error) {
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, 'Info');
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          this.removePreloader();
        }
      }
    },

    showSpecifications() {
      this.specifications = this.dataSpecifications.name;
      this.idSpecifications = this.dataSpecifications.id;
      this.subCategoryId = this.dataSpecifications.sub_category_id;
    },
    setAction() {
      if (this.isSpecifications) {
        this.updateSpecification();
      } else {
        this.createSpecification();
      }
    },
    cancel() {
      this.$emit('closeModal');
    },
  },
};
</script>
