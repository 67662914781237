<template>
  <div>
    <b-card>
      <!-- <b-row>
        <b-col md="6">
          <h2>Inventory Settings</h2>
        </b-col>
      </b-row> -->

      <b-card-body>
        <b-row>
          <b-col md="6">
            <b-card
              style="border: 1px solid #99969a !important"
              class="card-transaction"
              no-body
            >
              <b-card-header>
                <b-card-title>PACKINGS</b-card-title>
              </b-card-header>
              <div style="text-align: right; margin-right: 24px">
                <b-button
                  variant="outline-success"
                  size="sm"
                  @click="showModalPackings = true"
                ><feather-icon
                  icon="PlusIcon"
                  size="15"
                  class="text-success"
                />CREATE PACKING</b-button>
              </div>
              <b-card-body>
                <div class="fixed-height">
                  <b-table
                    ref="packingTable"
                    sticky-header
                    striped
                    responsive="sm"
                    show-empty
                    :items="getPackings"
                    :fields="fieldsPacking"
                  >
                    <template #cell(created_at)="data">
                      <span>
                        {{ data.item.created_at | myGlobalDay }}
                      </span>
                    </template>
                    <template #cell(deleted_at)="data">
                      <span
                        v-if="data.item.deleted_at == null || data.item.deleted_at == ''"
                        class="text-success"
                      >
                        ACTIVE
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      >
                        INACTIVE
                      </span>
                    </template>
                    <template #cell(actions)="data">
                      <feather-icon
                        v-b-tooltip.hover.top="'Edit Packing'"
                        icon="EditIcon"
                        size="18"
                        class="cursor-pointer text-warning mr-1"
                        @click="updatePacking(data.item)"
                      />
                      <feather-icon
                        v-if="data.item.deleted_at == null || data.item.deleted_at == ''"
                        v-b-tooltip.hover.top="'Inactive Packing'"
                        icon="ThumbsDownIcon"
                        size="15"
                        class="text-danger cursor-pointer"
                        @click="isActivePacking(data.item , 2)"
                      />
                      <feather-icon
                        v-else
                        v-b-tooltip.hover.top="'Active Packing'"
                        v-el
                        icon="ThumbsUpIcon"
                        size="15"
                        class="text-success cursor-pointer"
                        @click="isActivePacking(data.item, 1)"
                      />
                    </template>
                  </b-table>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card
              style="border: 1px solid #99969a !important"
              class="card-transaction"
              no-body
            >
              <template #default>
                <b-card-header>
                  <b-card-title>CATEGORIES</b-card-title>
                </b-card-header>
                <div
                  v-if="isTabActive == 0"
                  style="text-align: right; margin-right: 24px"
                >
                  <b-button
                    variant="outline-success"
                    size="sm"
                    @click="showModalCategory = true"
                  ><feather-icon
                    icon="PlusIcon"
                    size="15"
                    class="text-success"
                  />CREATE CATEGORY</b-button>
                </div>
                <div
                  v-else
                  style="text-align: right; margin-right: 24px"
                >
                  <b-button
                    variant="outline-success"
                    size="sm"
                    @click="showModalSubCategory = true"
                  ><feather-icon
                    icon="PlusIcon"
                    size="15"
                    class="text-success"
                  />CREATE SUBCATEGORY</b-button>
                </div>
                <div>
                  <b-tabs
                    v-model="isTabActive"
                    pills
                    nav-class="mb-0 mt-2 ml-1 call-tabs"
                    active-nav-item-class="bg-primary box-shadow-info"
                    lazy
                  >
                    <div>
                      <b-tab :title-link-class="bgTabsNavs">
                        <template #title>
                          <span>Categories</span>
                        </template>
                        <b-card-body>
                          <div class="fixed-height2">
                            <b-table
                              ref="categoryTable"
                              sticky-header
                              striped
                              responsive="sm"
                              show-empty
                              :items="getCategory"
                              :fields="fieldsCategory"
                              class="mb-0 h-custom"
                            >
                              <template #table-busy>
                                <div class="text-center text-primary my-2">
                                  <b-spinner class="align-middle mr-1" />
                                  <strong>Loading ...</strong>
                                </div>
                              </template>
                              <template #cell(created_at)="data">
                                <span>
                                  {{ data.item.created_at | myGlobalDay }}
                                </span>
                              </template>
                              <template #cell(totalSubCategory)="data">
                                <span class="text-success">{{ data.item.totalSubCategory }}</span>
                              </template>
                              <template #cell(actions)="data">
                                <feather-icon
                                  v-if="data.item.created_by_id != 0"
                                  v-b-tooltip.hover.top="'Edit Category'"
                                  icon="EditIcon"
                                  size="18"
                                  class="cursor-pointer text-warning mr-1"
                                  @click="updateCategory(data.item)"
                                />
                                <feather-icon
                                  v-if="data.item.created_by_id != 0"
                                  v-b-tooltip.hover.top="'Delete Category'"
                                  icon="TrashIcon"
                                  size="15"
                                  class="text-danger cursor-pointer"
                                  @click="deleteCategory(data.item)"
                                />
                              </template>
                            </b-table>
                          </div>
                        </b-card-body>

                        <modal-categories
                          v-if="showModalCategory"
                          :data-category="dataUpdateCategory"
                          :is-category-update="isUpdateCategory"
                          @hidden="handleModalHiddenCategory"
                          @closeModal="closeModalCategory"
                          @onCreateCategory="refreshTableCategory"
                        />
                      </b-tab>

                      <b-tab :title-link-class="bgTabsNavs">
                        <template #title>
                          <span>SubCategories</span>
                        </template>
                        <b-card-body>
                          <div class="fixed-height2">
                            <b-table
                              ref="subCategoryTable"
                              show-empty
                              sticky-header
                              striped
                              responsive="sm"
                              hover
                              :items="getSubCategory"
                              :fields="fieldsSubCategory"
                              class="mb-0 h-custom"
                            >
                              <template #cell(created_at)="data">
                                <span>
                                  {{ data.item.created_at | myGlobalDay }}
                                </span>
                              </template>
                              <template #cell(actions)="data">
                                <feather-icon
                                  v-if="data.item.created_by_id != 0"
                                  v-b-tooltip.hover.top="'Edit Subcategory'"
                                  icon="EditIcon"
                                  size="18"
                                  class="cursor-pointer text-warning mr-1"
                                  @click="updateSubCategory(data.item)"
                                />
                                <feather-icon
                                  v-if="data.item.created_by_id != 0"

                                  v-b-tooltip.hover.top="'Delete Subcategory'"
                                  icon="TrashIcon"
                                  size="15"
                                  class="text-danger cursor-pointer"
                                  @click="deleteSubCategory(data.item)"
                                />
                              </template>
                            </b-table>
                          </div>
                        </b-card-body>
                        <modal-sub-categories
                          v-if="showModalSubCategory"
                          :data-sub-category="dataUpdateSubCategory"
                          :is-sub-category-update="isUpdateSubCategory"
                          :is-tab-active="isTabActive"
                          @hidden="handleModalHiddenSubCategory"
                          @closeModal="closeModalSubCategory"
                          @onCreateSubCategory="refreshTableSubCategory"
                        />
                      </b-tab>
                    </div>
                  </b-tabs>
                </div>
              </template>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card
              style="border: 1px solid #99969a !important"
              class="card-transaction"
              no-body
            >
              <b-card-header>
                <b-card-title>SPECIFICATIONS</b-card-title>
              </b-card-header>
              <div style="text-align: right; margin-right: 24px">
                <b-button
                  variant="outline-success"
                  size="sm"
                  @click="showModalSpecifications = true"
                ><feather-icon
                  icon="PlusIcon"
                  size="15"
                  class="text-success"
                />CREATE SPECIFICATION</b-button>
              </div>
              <b-card-body>
                <div class="fixed-height">
                  <b-table
                    ref="SpecificationsTable"
                    sticky-header
                    striped
                    responsive="sm"
                    show-empty
                    :items="getSpecifications"
                    :fields="fieldsSpecifications"
                  >
                    <template #cell(created_at)="data">
                      <span>
                        {{ data.item.created_at | myGlobalDay }}
                      </span>
                    </template>
                    <template #cell(actions)="data">
                      <feather-icon

                        v-if="data.item.created_by_id != 0"

                        v-b-tooltip.hover.top="'Edit Specification'"
                        icon="EditIcon"
                        size="18"
                        class="cursor-pointer text-warning mr-1"
                        @click="updateSpecifications(data.item)"
                      />
                      <feather-icon

                        v-if="data.item.created_by_id != 0"

                        v-b-tooltip.hover.top="'Delete Specification'"
                        icon="TrashIcon"
                        size="15"
                        class="text-danger cursor-pointer"
                        @click="deleteSpecifications(data.item)"
                      />
                    </template>
                  </b-table>
                </div>
              </b-card-body>
              <modal-specifications
                v-if="showModalSpecifications"
                :data-specifications="dataUpdateSpecifications"
                :is-specifications="isUpdateSpecifications"
                @hidden="handleModalHiddenSpecifications"
                @closeModal="closeModalSpecifications"
                @onCreateSpecification="refreshTableSpecifications"
              />
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card
              style="border: 1px solid #99969a !important"
              class="card-transaction"
              no-body
            >
              <b-card-header>
                <b-card-title>VENDORS QUALIFICATION CRITERIA</b-card-title>
              </b-card-header>
              <div style="text-align: right; margin-right: 24px">
                <b-button
                  variant="outline-success"
                  size="sm"
                  @click="showModalCriteria = true"
                ><feather-icon
                  icon="PlusIcon"
                  size="15"
                  class="text-success"
                />CREATE CRITERIA</b-button>
              </div>
              <b-card-body>
                <div class="fixed-height">
                  <b-table
                    ref="QualificationTable"
                    sticky-header
                    striped
                    responsive="sm"
                    show-empty
                    :items="getCriteria"
                    :fields="fieldsCriteria"
                  >
                    <template #cell(created_at)="data">
                      <span>
                        {{ data.item.created_at | myGlobalDay }}
                      </span>
                    </template>
                    <template #cell(actions)="data">
                      <feather-icon

                        v-if="data.item.created_by_id != 0"

                        v-b-tooltip.hover.top="'Edit Criteria'"
                        icon="EditIcon"
                        size="18"
                        class="cursor-pointer text-warning mr-1"
                        @click="updateCriteria(data.item)"
                      />


                      <feather-icon v-if="data.item.id != 4 && data.item.created_by_id != 0"

                        v-b-tooltip.hover.top="'Delete Criteria'"
                        icon="TrashIcon"
                        size="15"
                        class="text-danger cursor-pointer"
                        @click="deleteCriteria(data.item)"
                      />
                    </template>
                  </b-table>
                </div>
              </b-card-body>
              <modal-criteria
                v-if="showModalCriteria"
                :data-criteria="dataUpdateCriteria"
                :is-criteria="isUpdateCriteria"
                @hidden="handleModalHiddenCriteria"
                @closeModal="closeModalCriteria"
                @onCreateCriteria="refreshTableCriteria"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <modal-packings
      v-if="showModalPackings"
      :data-account="dataUpdate"
      :is-update="isUpdate"
      @hidden="handleModalHiddenPackings"
      @closeModal="closeModalPackings"
      @onCreatePackings="refreshTablePackings"
    />
  </div>
</template>

<script>
import SettingsService from '@/views/logistic/views/settings/services/settings.service.js';
import ModalPackings from '@/views/logistic/views/settings/components/ModalPackings.vue';
import ModalCategories from '@/views/logistic/views/settings/components/ModalCategories.vue';
import ModalSubCategories from '@/views/logistic/views/settings/components/ModalSubCategories.vue';
import ModalSpecifications from '@/views/logistic/views/settings/components/ModalSpecifications.vue';
import ModalCriteria from '@/views/logistic/views/settings/components/ModalCriteria.vue';

export default {
  components: {
    ModalPackings,
    ModalCategories,
    ModalSubCategories,
    ModalSpecifications,
    ModalCriteria,
  },
  data() {
    return {
      showModalCriteria: false,
      showModalSpecifications: false,
      showModalPackings: false,
      showModalCategory: false,
      showModalSubCategory: false,

      listSubCategory: [],
      listSpecifications: [],
      listCriteria: [],

      isTabActive: null,
      isUpdate: false,
      isUpdateCategory: false,
      isUpdateSubCategory: false,
      isUpdateSpecifications: false,
      isUpdateCriteria: false,

      dataUpdateCategory: {},
      dataUpdateSubCategory: {},
      dataUpdate: {},
      dataUpdateSpecifications: {},
      dataUpdateCriteria: {},

      fieldsPacking: [
        { key: 'name', label: 'Packing Type' },
        { key: 'full_name', label: 'created by' },
        { key: 'created_at' },
        { key: 'deleted_at', label: 'STATUS' },
        { key: 'actions' },
      ],
      fieldsCategory: [
        { key: 'name', label: 'category' },
        { key: 'created_at' },
        { key: 'created_by' },
        {
          key: 'totalSubCategory',
          label: '# SUBCATEGORIES',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        { key: 'actions' },
      ],
      fieldsSubCategory: [
        { key: 'name', label: 'Subcategory' },
        { key: 'category' },
        { key: 'created_at' },
        { key: 'created_by' },
        { key: 'actions' },
      ],
      fieldsSpecifications: [
        { key: 'name', label: 'Specification' },
        { key: 'subcategory' },
        { key: 'created_at' },
        { key: 'created_by' },
        { key: 'actions' },
      ],
      fieldsCriteria: [
        { key: 'name', label: 'Criteria' },
        { key: 'country' },
        { key: 'created_at' },
        { key: 'created_by' },
        { key: 'actions' },
      ],
    };
  },
  created() {
    this.getPackings();
    this.getCategory();
    this.getSubCategory();
    this.getSpecifications();
    this.getCriteria();
  },
  methods: {
    async getPackings() {
      try {
        const request = await SettingsService.getPackings();
        if (request.status == 200) {
          return request.data || [];
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getCategory() {
      try {
        const request = await SettingsService.getCategory();
        if (request.status == 200) {
          return request.data || [];
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getSubCategory() {
      try {
        const request = await SettingsService.getSubCategory();
        if (request.status == 200) {
          this.listSubCategory = request.data || [];
          return this.listSubCategory;
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getSpecifications() {
      try {
        const request = await SettingsService.getSpecifications();
        if (request.status == 200) {
          this.listSpecifications = request.data || [];
          return this.listSpecifications;
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getCriteria() {
      try {
        const request = await SettingsService.getCriteria();
        if (request.status == 200) {
          this.listCriteria = request.data || [];
          return this.listCriteria;
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async isActivePacking(params, option) {
      try {
        const resultState = await this.showConfirmSwal();
        const param = {
          id: params.id,
          isActive: option,
        };
        if (resultState.isConfirmed) {
          this.addPreloader();
          const resul = await SettingsService.deletePacking(param);
          if (resul.data != 1) {
            this.showInfoSwal(resul.data);
          } else {
            this.showSuccessSwal();
            this.refreshTablePackings();
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async deleteCategory(params) {
      try {
        const resultState = await this.showConfirmSwal();
        const param = {
          id: params.id,
        };
        if (resultState.isConfirmed) {
          this.addPreloader();
          const resul = await SettingsService.deleteCategory(param);
          if (resul.status == 200) {
            this.showSuccessSwal();
            this.refreshTableCategory();
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async deleteSubCategory(params) {
      try {
        const resultState = await this.showConfirmSwal();
        const param = {
          id: params.id,
        };
        if (resultState.isConfirmed) {
          this.addPreloader();
          const resul = await SettingsService.deleteSubCategory(param);
          if (resul.status == 200) {
            this.showSuccessSwal();
            this.refreshTableSubCategory();
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async deleteSpecifications(params) {
      try {
        const resultState = await this.showConfirmSwal();
        const param = {
          id: params.id,
        };
        if (resultState.isConfirmed) {
          this.addPreloader();
          const resul = await SettingsService.deleteSpecifications(param);
          if (resul.status == 200) {
            this.showSuccessSwal();
            this.refreshTableSpecifications();
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async deleteCriteria(params) {
      try {
        const resultState = await this.showConfirmSwal();
        const param = {
          id: params.id,
        };
        if (resultState.isConfirmed) {
          this.addPreloader();
          const resul = await SettingsService.deleteCriteria(param);
          if (resul.status == 200) {
            this.showSuccessSwal();
            this.refreshTableCriteria();
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    updatePacking(params) {
      this.isUpdate = true;
      this.showModalPackings = true;
      this.dataUpdate = params;
    },
    updateCategory(params) {
      this.isUpdateCategory = true;
      this.showModalCategory = true;
      this.dataUpdateCategory = params;
    },
    updateSubCategory(params) {
      this.isUpdateSubCategory = true;
      this.showModalSubCategory = true;
      this.dataUpdateSubCategory = params;
    },
    updateSpecifications(params) {
      this.isUpdateSpecifications = true;
      this.showModalSpecifications = true;
      this.dataUpdateSpecifications = params;
    },
    updateCriteria(params) {
      this.isUpdateCriteria = true;
      this.showModalCriteria = true;
      this.dataUpdateCriteria = params;
    },
    closeModalPackings() {
      this.showModalPackings = false;
      this.isUpdate = false;
      this.dataUpdate = {};
    },
    closeModalCategory() {
      this.showModalCategory = false;
      this.isUpdateCategory = false;
      this.dataUpdateCategory = {};
    },
    closeModalSpecifications() {
      this.showModalSpecifications = false;
      this.isUpdateSpecifications = false;
      this.dataUpdateSpecifications = {};
    },
    closeModalCriteria() {
      this.showModalCriteria = false;
      this.isUpdateCriteria = false;
      this.dataUpdateCriteria = {};
    },
    closeModalSubCategory() {
      this.showModalSubCategory = false;
      this.isUpdateSubCategory = false;
      this.dataUpdateSubCategory = {};
    },
    handleModalHiddenPackings() {
      this.showModalPackings = false;
      this.isUpdate = false;
      this.dataUpdate = {};
    },
    handleModalHiddenCategory() {
      this.showModalCategory = false;
      this.isUpdateCategory = false;
      this.dataUpdateCategory = {};
    },
    handleModalHiddenSpecifications() {
      this.showModalSpecifications = false;
      this.isUpdateSpecifications = false;
      this.dataUpdateSpecifications = {};
    },
    handleModalHiddenSubCategory() {
      this.showModalSubCategory = false;
      this.isUpdateSubCategory = false;
      this.dataUpdateSubCategory = {};
    },
    handleModalHiddenCriteria() {
      this.showModalCriteria = false;
      this.isUpdateCriteria = false;
      this.dataUpdateCriteria = {};
    },
    refreshTablePackings() {
      this.$refs.packingTable.refresh();
    },
    refreshTableCategory() {
      this.$refs.categoryTable.refresh();
    },
    refreshTableSubCategory() {
      this.$refs.subCategoryTable.refresh();
    },
    refreshTableSpecifications() {
      this.$refs.SpecificationsTable.refresh();
    },
    refreshTableCriteria() {
      this.$refs.QualificationTable.refresh();
    },
  },
};
</script>

<style scoped>
.body-msg-setting {
  padding: 20px;
  background: #323131;
  border-radius: 10px;
  min-height: 180px;
  max-height: 180px;
  overflow-y: scroll !important;
}

.body-msg-setting-light {
  padding: 20px;
  background: #efefef;
  border-radius: 10px;
  min-height: 180px;
  max-height: 180px;
  overflow-y: scroll !important;
}

.fixed-height {
  height: 300px;
  /* Establece la altura deseada */
}
.fixed-height2 {
  height: 220px;
  /* Establece la altura deseada */
}
.h-custom {
  height: 250px;
}
</style>
