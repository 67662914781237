<template>
  <div>
    <b-modal
      no-close-on-backdrop
      @hidden="$emit('hidden')"
      ref="modal-sub-category"
      size="sm"
      :title="title + ' SUBCATEGORY'"
    >
      <div class="m-2">
        <validation-observer ref="observer">
          <div class="form-row">
            <div class="form-group col-md-12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="subcategory"
              >
                <b-form-group label="SUBCATEGORY">
                  <b-form-input
                    autofocus
                    v-model="subCategory"
                    type="text"
                    class="form-control"
                    :class="errors[0]"
                  >
                  </b-form-input>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="categorys"
              >
                <b-form-group
                  :class="errors[0]"
                  label="CATEGORY"
                  v-if="isTabActive"  
                >
                  <v-select
                    v-model="categoryId"
                    label="name"
                    :options="listCategory"
                    :reduce="(item) => item.id"
                  >
                  </v-select> </b-form-group
                >
              </validation-provider>
            </div>
          </div>
        </validation-observer>
      </div>
      <template #modal-footer="{ cancel }">
        <div>
    <button-cancel  @click="cancel()">CANCEL</button-cancel>
        </div>
        <div>
          <b-button variant="primary" @click="setAction()"
            ><feather-icon icon="SaveIcon" />
            {{ textButton }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SettingsService from "@/views/logistic/views/settings/services/settings.service.js";
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "is-invalid",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ButtonCancel
  },

  props: {
    dataSubCategory: {
      type: Object,
    },
    isSubCategoryUpdate: {
      type: Boolean,
      default: false,
    },
    isTabActive: {
      type: null,
      default: false,
    },
  },
  data() {
    return {
      listCategory: [],
      categoryId: "",
      subCategory: "",
      category: "",
      id: 0,
      idSubCategory: 0,
      title: "ADD",
      textButton: "SAVE",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modal-sub-category");

    if (this.isCategoryUpdate) {
      this.showCategory();
      this.title = "UPDATE";
      this.textButton = "UPDATE";
    }
    if (this.isSubCategoryUpdate) {
      this.showSubCategory();
      this.title = "UPDATE";
      this.textButton = "UPDATE";
    }
    if (this.isTabActive) {
      this.getCategory();
    }
  },

  methods: {
    async getCategory() {
      try {
        const request = await SettingsService.getCategory();
        if (request.status == 200) {
          this.listCategory = request.data || [];
          return this.listCategory;
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async createSubCategory() {
      const result = await this.$refs.observer.validate();

      if (result) {
        try {
          const params = {
            categoryId: this.categoryId,
            subCategory: this.subCategory,
            user_id: this.currentUser.user_id,
          };

          const resultState = await this.showConfirmSwal();

          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await SettingsService.saveSubCategory(params);
            if (resul.status == 200) {
              this.showSuccessSwal();
              this.cancel();
              this.$emit("onCreateSubCategory");
            }
          }
        } catch (error) {
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, "Info");
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          this.removePreloader();
        }
      }
    },
    async updateSubCategory() {
      const result = await this.$refs.observer.validate();

      if (result) {
        try {
          const params = {
            subCategory: this.subCategory,
            categoryId: this.categoryId,
            idSubCategory: this.idSubCategory,
            user_id: this.currentUser.user_id,
          };

          const resultState = await this.showConfirmSwal();

          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await SettingsService.updateSubCategory(params);
            if (resul.status == 200) {
              this.showSuccessSwal();
              this.cancel();
              this.$emit("onCreateSubCategory");
            }
          }
        } catch (error) {
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, "Info");
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          this.removePreloader();
        }
      }
    },
    showSubCategory() {
      this.subCategory = this.dataSubCategory.name;
      this.idSubCategory = this.dataSubCategory.id;
      this.categoryId = this.dataSubCategory.category_id;
    },
    setAction() {

     if (this.isSubCategoryUpdate) {
        this.updateSubCategory();
      } else{
        this.createSubCategory();
      } 
    },
    cancel() {
      this.$emit("closeModal");
    },
  },
};
</script>
