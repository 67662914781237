<template>
  <div>
    <b-modal
      no-close-on-backdrop
      @hidden="$emit('hidden')"
      ref="modal-criteria"
      size="sm"
      :title="title + ' CRITERIA'"
    >
      <div class="m-2">
        <validation-observer ref="observer">
          <div class="form-row">
            <div class="form-group col-md-12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="criteria"
              >
                <b-form-group label="CRITERIA">
                  <b-form-input
                    autofocus
                    v-model="criteria"
                    type="text"
                    class="form-control"
                    :class="errors[0]"
                  >
                  </b-form-input>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <!-- <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="detail"
              > -->
                <b-form-group label="DETAIL">
                  <b-form-textarea
                    id="detail"
                    v-model="detail"
                    placeholder="Describe a detail..."
                    rows="3"
                    max-rows="6"
                    type="text"
                  >
                  </b-form-textarea>
                </b-form-group>
              <!-- </validation-provider> -->
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="idCountries"
              >
                <b-form-group :class="errors[0]" label="VENDOR COUNTRY">
                  <v-select
                    v-model="idCountries"
                    label="text"
                    :options="countriesList"
                    :reduce="(item) => item.id"
                  />
                </b-form-group>
              </validation-provider>
            </div>
          </div>
        </validation-observer>
      </div>
      <template #modal-footer="{ cancel }">
        <div>
            <button-cancel   @click="cancel()">CANCEL</button-cancel>
        </div>
        <div>
          <b-button variant="primary" @click="setAction()"
            ><feather-icon icon="SaveIcon" />
            {{ textButton }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SettingsService from "@/views/logistic/views/settings/services/settings.service.js";
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "is-invalid",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ButtonCancel
  },

  props: {
    dataCriteria: {
      type: Object,
    },
    isCriteria: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countriesList: [
        { id: 75, text: "United States" },
        { id: 173, text: "Perú" },
      ],
      idCountries: "",
      criteria: "",
      id: 0,
      detail: "",
      title: "ADD",
      textButton: "SAVE",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modal-criteria");

    if (this.isCriteria) {
      this.showCriteria();
      this.title = "UPDATE";
      this.textButton = "UPDATE";
    }
  },

  methods: {
    async createCriteria() {
      const result = await this.$refs.observer.validate();

      if (result) {
        try {
          const params = {
            idCountries: this.idCountries,
            criteria: this.criteria,
            detail: this.detail,
            user_id: this.currentUser.user_id,
          };

          const resultState = await this.showConfirmSwal();

          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await SettingsService.saveCriteria(params);
            if (resul.status == 200) {
              this.showSuccessSwal();
              this.cancel();
              this.$emit("onCreateCriteria");
            }
          }
        } catch (error) {
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, "Info");
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          this.removePreloader();
        }
      }
    },
    async updateCriteria() {
      const result = await this.$refs.observer.validate();

      if (result) {
        try {
          const params = {
            idCountries: this.idCountries,
            criteria: this.criteria,
            detail: this.detail,
            id: this.id,
            user_id: this.currentUser.user_id,
          };

          const resultState = await this.showConfirmSwal();

          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await SettingsService.updateCriteria(params);
            if (resul.status == 200) {
              this.showSuccessSwal();
              this.cancel();
              this.$emit("onCreateCriteria");
            }
          }
        } catch (error) {
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, "Info");
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          this.removePreloader();
        }
      }
    },
    showCriteria() {
      this.criteria = this.dataCriteria.name;
      this.id = this.dataCriteria.id;
      this.detail = this.dataCriteria.detail;
      this.idCountries = this.dataCriteria.country_id;
    },
    setAction() {
      if (this.isCriteria) {
        this.updateCriteria();
      } else {
        this.createCriteria();
      }
    },
    cancel() {
      this.$emit("closeModal");
    },
  },
};
</script>
